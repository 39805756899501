import {createGlobalStyle} from 'styled-components';
import theme from './theme';

const variables = ['primary', 'secondary', 'success', 'danger', 'warning', 'silver', 'grey', 'info', 'light', 'orange',];

type ThemeOption = typeof theme;

const getTextColors = (themeOpt: any) => variables
    .map((val) => `
         .text-${val} {
            color: ${themeOpt?.[val]} !important;
         }`)
    .join('');

const getBgColors = (themeOpt: any) => variables
    .map((val) => `
        .bg-${val} {
            background-color: ${themeOpt?.[val]} !important;
         }`)
    .join('');

const getBorderColors = (themeOpt: any) => variables
    .map((val) => `
        .border-${val} {
            border-color: ${themeOpt?.[val]} !important;
         }`)
    .join('');

const GlobalStyle = createGlobalStyle<{ theme: ThemeOption }>`
   ${getTextColors(theme)}
   ${getBgColors(theme)}
   ${getBorderColors(theme)}
   .cursor-pointer {
        cursor: pointer;
   }

   .cursor-none {
        cursor: none;
        pointer-events: none;
    }

   .border-bottom {
        border-bottom: 1px solid ${theme.silver} !important;
   }

   .nav-dropdown {
        flex-direction: row-reverse;
   }
   .align-sub {
        vertical-align: sub !important;
   }
   .file-input {
        .form-control:: placeholder {
            color: ${theme.secondary}
        }
   }

    .form-control {
        &:focus {
            box-shadow: none !important;
        }
        &:read-only {
            color: ${theme.silver} !important;
        }
    }
    .badge {
        &.bg-warning {
            background-color: ${theme.warning}33 !important;

        }
    }
    .arial {
        font-family: Arial;
    }
    .signpad-canvass {
        touch-action: none;
        user-select: none;

    }
    .pre-wrap {
        text-wrap: wrap !important;
    }

   .loader {
       z-index: 9999;
       background-color: ${theme.white} !important;
   }

   .hasDisclaimer {
       position: relative;
   }

   .hasDisclaimer::after {
       position: absolute;
       left: 100%;
       top: 0;
       font-size: 12px;
   }

   .cloverPlatformFeeTxnFee::after {
       content: '**';
   }
   .Toastify__toast-container:has(div.warning-toast) {
        width: 500px;
        bottom: 80px;
    }
    @media (max-width: 600px) {
        .Toastify__toast-container:has(div.warning-toast) {
            width: auto;
        }
    }

   .required-with-error {
       background-color: ${theme.requiredWithError.backgroundColor};
       border: ${theme.requiredWithError.border}
   }

    @media (max-width: 460px) {
      table thead {
        display: none;
      }
      table td {
        display: flex;
      }
      table td::before {
        content: attr(aria-label);
        font-weight: bold;
        width: 120px;
        min-width: 120px;
        text-align: left;
      }
}
`;

export default GlobalStyle;
