import { FormControl } from 'react-bootstrap';
import styled from 'styled-components';

export const TextInput = styled(FormControl)`
    background-color: ${(props) => props.theme?.input?.backgroundColor} !important;
    border: ${(props) => props.border ?? props.theme?.input?.border} !important;
    color: ${(props) => props.theme?.primary} !important;
    border-radius: 4px;
    &:focus {
        box-shadow: none !important;
    }
    &.required-with-error {
        background-color: ${(props) => props.theme?.requiredWithError?.backgroundColor} !important;
        border: ${(props) => props.theme?.requiredWithError?.border} !important;
    }
`;

export default TextInput;
