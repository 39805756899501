"use client";

import React, { useEffect } from 'react';
import { ThemeProvider } from "styled-components";
import theme from "src/theme/theme";
import "../sass/App.scss";
import { AuthContextProvider } from '@auth/useAuth';
import { AppContainer } from "@common";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Datadog from '@components/Datadog';
import ToastProvider from '@components/Toaster/ToastProvider';
import StyledComponentsRegistry from "./lib/registry";

const RootLayout = ({ children, }: { children: React.ReactNode }) => {
    const queryClient = new QueryClient();

    useEffect(() => {
        if (window && window.innerWidth < 575) {
            const vh = window.innerHeight * 0.01;

            document.documentElement.style.setProperty("--vh", `${vh}px`);
            window.addEventListener("resize", () => {
                document.documentElement.style.setProperty(
                    "--vh",
                    `${window.innerHeight * 0.01}px`
                );
            });
        }
    }, []);

    return <html lang="en">
        <head>
            <title>Application | Gravity Payments</title>
            <meta name="title" content="Application | Gravity Payments" />
            <meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimal-ui"/>
            <meta name="description" content="The most trusted name in credit card processing. Accept payments any time, any where. Gravity integrates with hundreds of hardware and software solutions." />
            <link rel="stylesheet" href="https://use.typekit.net/qiv4ryh.css" />
        </head>
        <body className='overflow-hidden'>
            <Datadog />
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <AppContainer>
                        <StyledComponentsRegistry>
                            <AuthContextProvider>
                                <ToastProvider>
                                    {children}
                                </ToastProvider>
                            </AuthContextProvider>
                        </StyledComponentsRegistry>
                    </AppContainer>
                </QueryClientProvider>
            </ThemeProvider>
        </body>
    </html>;
}
export default RootLayout;
