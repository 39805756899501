import { Button as BootstrapButton } from 'react-bootstrap';
import styled from 'styled-components';

export const Button = styled(BootstrapButton)`
    background-color: ${(props) => props.theme?.[props?.variant || 'primary']};
    border-color: ${(props) => props.theme?.[props?.variant || 'primary']};
    color: ${(props) => props.theme?.[props?.color || 'white']};
    border-radius: ${(props) => props.theme?.btn?.radius};
    font-size: ${(props) => props.theme?.fontSize?.[props?.fontSize || 'sm']};
    &:hover {
        background-color: ${(props) => props.theme?.[props?.variant || 'primary']};
        border-color: ${(props) => props.theme?.[props?.variant || 'primary']};
    }
    &:focus {
        background-color: ${(props) => props.theme?.[props?.variant || 'primary']};
        border-color: ${(props) => props.theme?.[props?.variant || 'primary']};
        box-shadow: none;
    }
    &:active {
        background-color: ${(props) => props.theme?.[props?.variant || 'primary']};
        border-color: ${(props) => props.theme?.[props?.variant || 'primary']};
        box-shadow: none;
    }
`;

export default Button;
