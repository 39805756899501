import { useEffect } from 'react';

type Props = {
    isAuthenticated: boolean;
    logout: () => Promise<void>;
}
/**
 * Automatically logs the user out after 1 hour of inactivity
 */
export const useAutoLogout = ({ isAuthenticated, logout }: Props) => {
    useEffect(() => {
        const rawLastActivity = localStorage.getItem('lastActivity');
        if (rawLastActivity) {
            const lastActivity = new Date(rawLastActivity);
            const oneHourFromLastActivity = new Date(lastActivity.getTime() + 60 * 60 * 1000);
            if (new Date() > oneHourFromLastActivity) {
                logout();
            }
        }
        return () => isAuthenticated
            ? localStorage.setItem('lastActivity', new Date().toISOString()) // setLastActivity on unmount
            : localStorage.removeItem('lastActivity'); // removeLastActivity on logout
    }, [isAuthenticated, logout]);
}
