import Cleave from "cleave.js/react";
import styled from "styled-components";

export const CleaveInput = styled(Cleave).attrs((props) => ({
    ...props,
    className: "form-control",
}))`
    background-color: ${(props) => props.theme?.input?.backgroundColor} !important;
    border: ${(props) => props.theme?.input?.border} !important;
    color: ${(props) => props.theme?.primary} !important;
    border-radius: 4px;
    &:foucs {
        box-shadow: none !important;
    }
    &.required-with-error {
        background-color: ${(props) => props.theme?.requiredWithError?.backgroundColor} !important;
        border: ${(props) => props.theme?.requiredWithError?.border} !important;
    }
`;

export default CleaveInput;
