import { Button as BootstrapButton } from 'react-bootstrap';
import styled from 'styled-components';

export const OutlineButton = styled(BootstrapButton)`
    background-color: transparent !important;
    border-color: ${(props) => props.theme?.[props?.variant || 'primary']};
    color: ${(props) => props.theme?.[props?.color || props?.variant || 'primary']};
    font-size: ${(props) => props.theme?.fontSize?.[props?.fontSize || 'sm']};
    border-radius: ${(props) => props.theme?.btn?.radius};
    &:hover {
        background-color: ${(props) => props.theme?.[props?.variant || 'primary']} !important;
        border-color: ${(props) => props.theme?.[props?.variant || 'primary']};
        color: white !important;
        svg {
            path {
                fill: white !important;
            }
        }
    }
    &:focus {
        border-color: ${(props) => props.theme?.[props?.variant || 'primary']};
        box-shadow: none;
        color: ${(props) => props.theme?.[props?.variant || 'primary']};
    }
    &:active {
        border-color: ${(props) => props.theme?.[props?.variant || 'primary']};
        box-shadow: none;
    }
`;

export default OutlineButton;
