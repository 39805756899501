import styled from 'styled-components';

export const AppContainer = styled.div`
    font-size: ${(props) => props.theme.fontSize.md};
    font-family: ${(props) => props.theme.fontFamily.primary};
    color: ${(props) => props.theme.primary};
    background-color: ${(props) => props.theme.backgroundColor};
    overflow: hidden;
    width: 100%;
    font-display: swap;
`;

export default AppContainer;
