'use client';

import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum';

if (process.env.NEXT_PUBLIC_DD_ENABLED === "true") {
    datadogRum.init({
        applicationId: "d41319af-da93-4f01-b81b-351260d3a4db",
        clientToken: "pub5c2894bdbeac89e599bfe7789f451dfd",
        site: "datadoghq.com",
        env: process.env.NEXT_PUBLIC_APP_ENV,
        service: "merchant-boarding",
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        defaultPrivacyLevel: "allow",
        version: process.env.NEXT_PUBLIC_APP_VERSION,
    });
    datadogLogs.init({
        clientToken: 'pub5c2894bdbeac89e599bfe7789f451dfd',
        env: process.env.NEXT_PUBLIC_APP_ENV,
        forwardConsoleLogs: 'all',
        forwardErrorsToLogs: true,
        service: 'merchant-boarding',
        sessionSampleRate: 100,
        site: 'datadoghq.com',
        version: process.env.NEXT_PUBLIC_APP_VERSION,
    });
    datadogRum.startSessionReplayRecording();
}

// Render nothing - this component is only included so that the init code
// above will run client-side
const Datadog = () => null;
export default Datadog;
