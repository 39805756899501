const theme = {
    primary: '#253750', // Navy Blue
    secondary: '#107AB0', // Blue
    success: '#519D18', // Green
    warning: '#E7A239', // Yellow
    info: '#545DA9', // Purple
    danger: '#DC2E50', // Red
    light: '#29A59C', // Tael
    silver: '#C9CED0', // silver
    orange: '#DC7539', // orange
    white: '#ffffff',
    backgroundColor: '#ffffff',
    grey: '#FAFAFB',
    charcoal: '#20282F',
    linkBlue: '#50C6DE',
    borderColor: '#C9CED0',
    pewter: '#7E868C',
    fontSize: {
        xss: '10px',
        xs: '12px',
        sm: '14px',
        md: '16px',
        lg: '18px',
        xl: '20px',
        title: '26px',
    },
    btn: {
        radius: '3px',
    },
    input: {
        backgroundColor: '#FAFAFB',
        border: '1px solid #C9CED0',
    },
    fontFamily: {
        primary: 'proxima-nova, Helvetica Neue',
    },
    requiredWithError: {
        backgroundColor: '#E7A2391A',
        border: '1px solid #E7A239',
    }
};

export default theme;
